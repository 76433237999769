@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

body {
  background: #F4F4F4;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background: #F4F4F4;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MontserratarmBlack';
  src: local('MontserratarmBlack'), url(./fonts/MontserratarmBlack.otf) format('opentype');
}

@font-face {
  font-family: 'MontserratarmBold';
  src: local('MontserratarmBold'), url(./fonts/MontserratarmBold.otf) format('opentype');
}

@font-face {
  font-family: 'MontserratarmExtraBold';
  src: local('MontserratarmExtraBold'), url(./fonts/MontserratarmExtraBold.otf) format('opentype');
}

@font-face {
  font-family: 'MontserratarmExtraLight';
  src: local('MontserratarmExtraLight'), url(./fonts/MontserratarmExtraLight.otf) format('opentype');
}

@font-face {
  font-family: 'MontserratarmLight';
  src: local('MontserratarmLight'), url(./fonts/MontserratarmLight.otf) format('opentype');
}

@font-face {
  font-family: 'MontserratarmMedium';
  src: local('MontserratarmMedium'), url(./fonts/MontserratarmMedium.otf) format('opentype');
}

@font-face {
  font-family: 'MontserratarmRegular';
  src: local('MontserratarmRegular'), url(./fonts/MontserratarmRegular.otf) format('opentype');
}

@font-face {
  font-family: 'MontserratarmSemiBold';
  src: local('MontserratarmSemiBold'), url(./fonts/MontserratarmSemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'MontserratarmThin';
  src: local('MontserratarmThin'), url(./fonts/MontserratarmThin.otf) format('opentype');
}
